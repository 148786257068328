import React from "react";
import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
} from "../../StyledComponents/mixdown.styles";
import { BackButton } from "../BackButton";

const MixdownErrorMobile = ({ data }) => {
  const { title, errorInfo } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      <div>
        <BackButton bgColor="#D82A2A" target={-2} />
      </div>
      <MixdownWrapper
        style={{ marginTop: "48px", height: "100%" }}
        justifyContent="space-between"
      >
        <LeftWrapper gap={"24px"}>
          <MixdownTitle>{title}</MixdownTitle>
          <SmallText>{errorInfo}</SmallText>
        </LeftWrapper>
      </MixdownWrapper>
    </>
  );
};

export default MixdownErrorMobile;
