import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";

import SEO from "../../components/seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
} from "../../StyledComponents/mixdown.styles";
import MixdownErrorMobile from "../../components/mixdownMobilePages/ErrorMobile";
import { FormContext } from "../../context/formContext";
import { setBackgroundForGatsbyWrappers } from "../../lib/backgroundColorSetter";
import { BackButton } from "../../components/BackButton";

const MixdownError = ({ data }) => {
  const { width } = useWindowSize();

  const isBelowTablet = width <= screenSizes.tablet;

  const { title, errorInfo } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const { setIsSubmissionError } = useContext(FormContext);

  useEffect(() => {
    setBackgroundForGatsbyWrappers("#D82A2A");
    setIsSubmissionError(true);

    return () => {
      setIsSubmissionError(false);
      setBackgroundForGatsbyWrappers("black");
    };
  }, [setIsSubmissionError]);

  return (
    <>
      {!isBelowTablet ? (
        <>
          <SEO title="SPANDAU20 Mixdown" />
          <MixdownWrapper
            justifyContent="space-between"
            style={{ maxWidth: "90%" }}
          >
            <LeftWrapper>
              <MixdownTitle>{title}</MixdownTitle>
              <SmallText>{errorInfo}</SmallText>
            </LeftWrapper>
            <BackButton bgColor="#D82A2A" />
          </MixdownWrapper>
        </>
      ) : (
        <MixdownErrorMobile data={data} />
      )}
    </>
  );
};

export default MixdownError;

export const query = graphql`
  query ErrorQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownError" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              errorInfo
            }
          }
        }
      }
    }
  }
`;
